import { Box, FormLabel, Grid, Typography } from "@mui/material";
import UploadBoxWithComponent from "components/atoms/UploadBoxWithComponent";
import { useEffect, useRef, useState } from "react";
import DeletableImage from "components/atoms/DeletableImage";
import { NewButton } from "components/atoms/NewButton";
import styled from "styled-components";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

export const App = () => {
  const [file, setFile] = useState<{
    file: File;
    objectUrl: string;
  } | null>();
  const [translation, setTranslation] = useState<string>("");
  const [text, setText] = useState<string>("");
  const [loading, setLoading] = useState(false);

  const isMounted = useRef(false);

  const TextBackground = styled.div`
    background-color: #d5d5d5;
    border-radius: 12px;
    padding: 1rem;
  `;

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  const handleFiles = async (_files: File[]) => {
    if (!_files[0]) {
      setFile(null);
      return;
    }
    setFile({
      file: _files[0],
      objectUrl: URL.createObjectURL(_files[0]),
    });
  };

  const handleTranslate = async () => {
    if (!file) {
      alert("Please select a file first!");
      return;
    }

    const formData = new FormData();
    formData.append("file", file.file);

    console.log({ file });
    setLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URI}/document/translate`,
        {
          method: "POST",
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error("File upload failed");
      }

      const data = await response.json();
      console.log("File upload successful:", data);
      setTranslation(data.document.translatedText);
      setText(data.document.rawContent);
      setLoading(false);
    } catch (error) {
      console.error("Error uploading file:", error);
      setLoading(false);
    }
  };

  const handleDelete = (imageUrl: string) => {
    setFile(null);
  };

  return (
    <div>
      <Box mt={5} px={4}>
        <Grid container>
          <Grid item xs={12}>
            <Box my={5}>
              <FormLabel>Documents</FormLabel>
            </Box>
            <Box my={5}>
              <UploadBoxWithComponent
                emptyAditionalLabel="to decode the QRCodes"
                onDrop={handleFiles}
              >
                {file && (
                  <Box
                    height="100%"
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <DeletableImage
                      handleDelete={handleDelete}
                      imageUrl={file.objectUrl}
                      loading={loading}
                    />
                  </Box>
                )}
              </UploadBoxWithComponent>
            </Box>
            <Grid container mx={0} my={5} spacing={6} width="100%">
              <Box>
                <NewButton
                  fullWidth={false}
                  onClick={handleTranslate}
                  loading={loading}
                >
                  Translate
                </NewButton>
              </Box>
            </Grid>
            {text && translation && (
              <Grid container mx={0} my={5} width="100%">
                <Box display="flex" justifyContent={"space-between"}>
                  <Box width={"calc(50% - 10px)"} padding={"1rem"}>
                    <Box height={"30px"} mb={2}>
                      <Typography>Original</Typography>
                    </Box>
                    <TextBackground>
                      <Typography>{text}</Typography>
                    </TextBackground>
                  </Box>
                  <Box width={"calc(50% - 10px)"} padding={"1rem"}>
                    <Box height={"30px"} mb={2}>
                      <Typography
                        onClick={() => {
                          navigator.clipboard.writeText(translation);
                        }}
                        sx={{
                          "&:hover": {
                            cursor: "pointer",
                          },
                        }}
                      >
                        Tradução{" "}
                        <span>
                          <ContentCopyIcon />
                        </span>
                      </Typography>
                    </Box>
                    <TextBackground>
                      <Typography>{translation}</Typography>
                    </TextBackground>
                  </Box>
                </Box>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default App;
