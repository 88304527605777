import { Box, CircularProgress } from "@mui/material";
import CancelSharpIcon from "@mui/icons-material/CancelSharp";
import styled from "styled-components";

export interface DeletableImageProps {
  handleDelete: (imageUrl: string) => void;
  imageUrl: string;
  loading: boolean;
}

const ResizeImg = styled.img`
  height: 100%;
  border-radius: 12px;
`;

const StatusBorder = styled.span`
  height: 100%;
  border-radius: 12px;
`;

const LoadingContainer = styled.span`
  background-color: #D5D5D5;
  opacity: 0.5;
  position: absolute;
  height: 100%;
  width: 100%;
`;

function DeletableImage({
  handleDelete,
  imageUrl,
  loading,
}: DeletableImageProps) {
  return (
    <Box
      display="flex"
      height="calc(100%)"
      minWidth="125px"
      position="relative"
      width="fit-content"
    >
      {loading ? (
        <LoadingContainer>
          <Box
            alignItems="center"
            display="flex"
            height="100%"
            justifyContent="center"
            width="100%"
          >
            <CircularProgress />
          </Box>
        </LoadingContainer>
      ) : (
        <></>
      )}
      <Box
        onClick={(e) => {
          e.stopPropagation();
          handleDelete(imageUrl);
        }}
        position="absolute"
        right="-15px"
        top="-15px"
      >
        <CancelSharpIcon />
      </Box>
      <StatusBorder>
        <ResizeImg src={imageUrl} />
      </StatusBorder>
    </Box>
  );
}

export default DeletableImage;
