import { Box, Typography } from "@mui/material";
import { PropsWithChildren, forwardRef, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import styled from "styled-components";
import FolderIcon from "../../assets/folder-icon.png";

type UploadBoxWithComponentProps = PropsWithChildren<{
  // define your props here
  emptyAditionalLabel?: string;
  onDrop?: (acceptedFiles: File[]) => void;
}>;

const Container = styled(Box)`
  padding: 1rem;
  margin: 1rem 0;
  border: 2px dashed #d5d5d5;
  border-radius: 10px;
  box-sizing: border-box;
  background-color: #e2e2e2;
  width: 100%;
  height: 22.5rem;
  z-index: 100;
  justify-content: start;
  align-items: center;
  display: flex;
  overflow-x: auto;

  &::-webkit-scrollbar {
    height: 0.5rem;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: #52333b;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

const UploadBoxWithComponent = forwardRef<
  HTMLInputElement,
  UploadBoxWithComponentProps
>(({ children, emptyAditionalLabel, onDrop }, ref) => {
  const handleDrop = useCallback(
    (acceptedFiles: File[]) => {
      onDrop?.(acceptedFiles);
    },
    [onDrop]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleDrop,
  });

  return (
    <Container component="section" {...getRootProps()}>
      {children ? (
        children
      ) : (
        <Box
          alignItems="center"
          display="flex"
          flexDirection="column"
          height="100%"
          justifyContent="center"
          width="100%"
        >
          <input {...getInputProps()} ref={ref} />
          <Box>
            <img src={FolderIcon} />
          </Box>
          {isDragActive ? (
            <Typography color="#343434" variant="body2">
              {`Drop the files here ${emptyAditionalLabel ? emptyAditionalLabel : ""}...`}
            </Typography>
          ) : (
            <Typography color="#343434" variant="body2">
              {`Drag n' drop some files here ${
                emptyAditionalLabel ? emptyAditionalLabel : ""
              }, or click to select files`}
            </Typography>
          )}
        </Box>
      )}
    </Container>
  );
});

UploadBoxWithComponent.displayName = "UploadBoxWithComponent";

export default UploadBoxWithComponent;
